.list-group-item {
    padding: 15px 20px;
    border-bottom: 1px solid #3d5a80;

    &:last-child {
        border-bottom: none;
    }

    & span {
        min-width: 550px;
        line-height: 35px;
        font-size: 20px;
        cursor: pointer;
    }

    & input {
        text-align: center;
        border: 0;
        line-height: 35px;
        font-size: 20px;
    }

    & button {
        width: 35px;
        height: 35px;
        margin: 3px;
        font-size: 17px;
        border: none;
        cursor: pointer;
    }

    & .btn-cookie {
        color: #e09f3e;
    }

    & .btn-trash {
        color: #e5383b;
    }

    & .fa-star {
        text-align: center;
        width: 35px;
        height: 35px;
        color: #FFD700;
        line-height: 35px;
        font-size: 16px;
        transition: 0.3s all;
        transform: translateX(30px);
        opacity: 0;
    }

    &.like .fa-star {
        opacity: 1;
        transform: translateX(0px);
    }

    &.increase {
        .btn-star {
            color: #aeaeae;
        }

        & .list-group-item-label,
        & .list-group-item-input {
            color: #e09f3e;
        }
    }
}